import produce from "immer";
import {
  FETCH_HEADER_MENU_LOADING,
  FETCH_HEADER_MENU,
  FETCH_APPLICATION_LOADING,
  FETCH_APPLICATION,
  FETCH_FOOTER_MENU_LOADING,
  FETCH_FOOTER_MENU,
} from "./actionTypes";

const initialState = {
  applicationLoading: false,
  applications: null,
  footerLoading: false,
  footerMenu: null,
  headerLoading: false,
  header: null,
};

const headerReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_HEADER_MENU_LOADING:
      return {
        ...state,
        headerLoading: payload,
      };
    case FETCH_HEADER_MENU:
      return {
        ...state,
        header: payload,
      };
    case FETCH_APPLICATION_LOADING:
      return {
        ...state,
        applicationLoading: payload,
      };
    case FETCH_APPLICATION:
      return {
        ...state,
        applications: payload,
      };
    case FETCH_FOOTER_MENU_LOADING:
      return {
        ...state,
        footerLoading: payload,
      };
    case FETCH_FOOTER_MENU:
      return {
        ...state,
        footerMenu: payload,
      };
    default:
      return state;
  }
});

export default headerReducer;
