import { combineReducers } from "redux";

import Dashboard from "modules/dashboard/store/reducer";
import Login from "modules/login/store/reducer";
import header from "layout/store/reducer";

const rootReducer = combineReducers({
  Login,
  Dashboard,
  header,
});

export default rootReducer;
