import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./index.module.css";

import Header from "./Header";
import Footer from "./Footer";
import RowSection from "./rowSection";

const Layout = () => {
  const [isAuth, setIsAuth] = useState(true);
  const getTempUrl = window.location.pathname;

  useEffect(() => {
    if (getTempUrl === "/login") {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, [getTempUrl]);

  return isAuth ? (
    <>
      <div className={styles.main_wrapper}>
        <div className={`${styles.main_header}`}>
          <div className={`container-fluid ${styles.main_container_wrapper}`}>
            <Header />
          </div>
        </div>
        <div className={`container-fluid ${styles.main_container_wrapper}`}>
          <div className={styles.container_wrapper}>
            <RowSection />
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  ) : (
    <Outlet />
  );
};

export default Layout;
