import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import { fetchFooter } from "./store/action";

const Footer = () => {
  const dispatch = useDispatch();
  const { footerMenu } = useSelector(state => state.header);

  useEffect(() => {
    dispatch(fetchFooter({ menu_type: 2, site_id: 1 }));
  }, [dispatch]);

  const list = useMemo(() => {
    if (!!footerMenu) {
      const data = [...footerMenu];
      return data?.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1)) || [];
    }
  }, [footerMenu]);

  return (
    <footer className={styles.footer_content}>
      <div className="container">
        <div className={styles.footer_info}>
          &#x40;
          {new Date().getFullYear()} Stuff You Can Use
          {list?.map((item, i) => {
            return (
              <Fragment key={i}>
                <span>{" | "}</span>
                <Link
                  to={{
                    pathname:
                      item?.system_pages_id > 0
                        ? item?.sycu_system_page?.page_link?.target_url
                        : "/" + item.link,
                  }}
                  target={item?.system_pages_id > 0 ? "_parent" : "_parent"}
                >
                  {item?.application_menu_title}
                </Link>
              </Fragment>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
