import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Routes from "routes";

import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get } from "services/cookies";
import { fetchUserProfile } from "store/actions";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const token = get("token");
    if (!token) {
      window.location.replace(
        `${process.env.REACT_APP_ACCOUNT_SITE_URL}/login?redirect=${window.location.href}&app_id=14`
      );
      return;
    }
    dispatch(fetchUserProfile());
    sessionStorage.clear();
  }, [dispatch]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
        limit={3}
      />
      <Routes />
    </>
  );
};

export default App;
