import { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../modules/dashboard/pages/index.module.css";

const PageContent = ({ setCheckData }) => {
  const { volume_data } = useSelector(state => state.Login);
  const [checkData] = useState(volume_data || []);

  const seriesColor = ["#7b7d80", "#ed8b4a", "#86cfdb", "#db5254", "#c8d970"];

  const handleChangeData = (
    type,
    parentId,
    isParent,
    semiParent,
    childId,
    checked,
    ministryType,
    categoryType,
    ministryId
  ) => {
    setCheckData(
      checkData.map(d => {
        if (ministryType === true) {
          if (d.tv_category_id === parentId) {
            if (isParent) {
              d.is_selected = !d.is_selected;
              d.volumes = d.volumes.map(rr => ({
                data: rr?.data?.map(x => ({
                  ...x,
                  is_selected: d.is_selected,
                  series: x.series.map(s => ({
                    ...s,
                    is_selected: d.is_selected,
                  })),
                })),
                type: rr?.type,
                is_selected: d.is_selected,
              }));
            } else {
              d.is_selected = !d.volumes?.some(s => s.is_selected) && false;
              d.volumes.map(rr => {
                // rr.type= rr?.type
                rr.is_selected =
                  semiParent === d.tv_category_id && childId === rr.type
                    ? checked
                    : d.is_selected;
                rr?.data?.map(x => {
                  // ...x,
                  x.is_selected =
                    semiParent === rr.type && childId === x.category_id
                      ? checked
                      : rr.is_selected;
                  x.series.map(s => {
                    s.is_selected =
                      semiParent === x.category_id && childId === s.category_id
                        ? checked
                        : x.is_selected
                        ? x.is_selected
                        : s.is_selected;
                  });
                });
              });
            }
          }
        } else {
          if (categoryType === false || categoryType === "videos") {
            ///families //volunteers
            if (d.tv_category_id === parentId) {
              if (isParent) {
                d.is_selected = !d.is_selected;
                d.videos = d?.videos?.map(rr => ({
                  ...rr,
                  is_selected: d.is_selected,
                }));
              } else {
                d.is_selected = !d?.videos?.some(s => s.is_selected) && false;
                d.videos = d.videos.map(rr => ({
                  ...rr,
                  is_selected:
                    rr.grow_tv_id === ministryId ? checked : rr.is_selected,
                }));
                if (d?.videos?.every(s => s.is_selected)) d.is_selected = true;
              }
            }
          } else {
            //tutorial
            if (d.tv_category_id === parentId) {
              if (isParent) {
                d.is_selected = !d.is_selected;
                d.tv_app_sub_category = d?.tv_app_sub_category?.map(rr => ({
                  ...rr,
                  is_selected: d.is_selected,
                }));
              } else {
                d.is_selected =
                  !d?.tv_app_sub_category?.some(s => s.is_selected) && false;
                d.tv_app_sub_category = d.tv_app_sub_category.map(rr => ({
                  ...rr,
                  is_selected:
                    rr.category_id === ministryId ? checked : rr.is_selected,
                }));
                if (d?.tv_app_sub_category?.every(s => s.is_selected))
                  d.is_selected = true;
              }
            }
          }
        }
        return d;
      })
    );
  };

  return checkData?.map((data, i) => (
    <div className="accordion-item" key={`${data.tv_category_id}${i}`}>
      <h2 className="accordion-header" id={"heading" + data.tv_category_id + i}>
        <div className="form-group">
          <input
            type="checkbox"
            id={`${data.tv_category_id}${i}`}
            checked={data.is_selected}
            onChange={e => {
              handleChangeData(
                data.tv_category_id,
                data.tv_category_id,
                true,
                null,
                null,
                e.target.checked,
                data?.volumes ? true : false,
                data?.tv_app_sub_category ? true : false
              );
            }}
          />
          <label htmlFor={`${data.tv_category_id}${i}`}></label>
        </div>
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#collapse" + data.tv_category_id + i}
          aria-expanded="false"
          aria-controls={"collapse" + data.tv_category_id + i}
        >
          {data.tv_category}
        </button>
      </h2>
      <div
        id={"collapse" + data.tv_category_id + i}
        className="accordion-collapse collapse"
        aria-labelledby={"heading" + data.tv_category_id + i}
        data-bs-parent="#accordionExample"
      >
        {data?.categories_details?.some(
          s =>
            s.detail_key === "category_type" &&
            (s.detail_value === "Kids" || s.detail_value === "Students")
        ) === true ? (
          data?.volumes?.map((x, j) => (
            <div className="accordion-body">
              <div className="accordion" id="accordionExampleinsideinner">
                {x.data?.map((item, y) => (
                  <div className="accordion-item">
                    <h2
                      className="accordion-header"
                      id={"headinginside" + item.category_id + i + j + y}
                    >
                      <div className="form-group">
                        <input
                          type="checkbox"
                          id={"one" + item.category_id + i + j + y}
                          onChange={e => {
                            handleChangeData(
                              item.category_id,
                              data.tv_category_id,
                              false,
                              x.type,
                              item.category_id,
                              e.target.checked,
                              true
                            );
                          }}
                          checked={x.is_selected ? true : item.is_selected}
                        />
                        <label
                          htmlFor={"one" + item.category_id + i + j + y}
                        ></label>
                      </div>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={
                          "#collapse" + item.category_id + i + j + y
                        }
                        aria-expanded="false"
                        aria-controls={
                          "collapse" + item.category_id + i + j + y
                        }
                      >
                        {item.category_title}
                      </button>
                    </h2>
                    <div
                      id={"collapse" + item.category_id + i + j + y}
                      className="accordion-collapse"
                      aria-labelledby="headinginsideone"
                      data-bs-parent="#accordionExampleinsideinner"
                    >
                      <div className="accordion-body">
                        {item.series.map((val, index) => (
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={"ser" + val.category_id + i + j + y + index}
                              onChange={e => {
                                handleChangeData(
                                  val.category_id,
                                  data.tv_category_id,
                                  false,
                                  item.category_id,
                                  val.category_id,
                                  e.target.checked,
                                  true
                                );
                              }}
                              checked={
                                item.is_selected ? true : val.is_selected
                              }
                            />
                            <label
                              htmlFor={
                                "ser" + val.category_id + i + j + y + index
                              }
                            >
                              <span
                                className="box"
                                style={{
                                  backgroundColor:
                                    seriesColor[index % seriesColor.length],
                                }}
                              ></span>
                              {val.category_title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : data?.categories_details?.some(
            s =>
              s.detail_key === "category_type" && s.detail_value === "Tutorials"
          ) ? (
          <div className="p-3 row">
            {data?.tv_app_sub_category?.map((site, key) => (
              <div className="col-lg-4 mb-2">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id={"one" + site + i + key}
                    checked={data.is_selected ? true : site.is_selected}
                    onChange={e => {
                      handleChangeData(
                        site.category_id,
                        site.tv_category_id,
                        false,
                        null,
                        null,
                        e.target.checked,
                        false,
                        "tutorial",
                        site.category_id
                      );
                    }}
                  />
                  <label htmlFor={"one" + site + i + key}>
                    <div>{site.category_title}</div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-3 row">
            {data?.videos?.map((vid, jj) => (
              <div className="col-lg-6 mb-3">
                <div className="d-flex text-center align-items-center category-video-invitation">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id={`${vid.grow_tv_id}${i}`}
                      checked={data.is_selected ? true : vid.is_selected}
                      onChange={e => {
                        handleChangeData(
                          vid.grow_tv_id,
                          vid.tv_category_id,
                          false,
                          null,
                          null,
                          e.target.checked,
                          false,
                          "videos",
                          vid.grow_tv_id
                        );
                      }}
                    />
                    <label htmlFor={`${vid.grow_tv_id}${i}`}>
                      <img
                        className={styles.video_thumb_image}
                        src={vid.thumbnail_image}
                        alt="video thumbnail"
                      />
                      <p className="ms-4">{vid.video_title}</p>
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  ));
};

export default PageContent;
