const urls = {
  GET_USER_PROFILE: "/getUserProfileDetail",
  LOG_OUT_USER: "/logOutUser",
  LIST_SITE: "/listSite",
  LIST_TV_CATEGORY: "/listTvCategory",
  LIST_TV_CATEGORY_VIDEOS: "/listTvCategoryVideo",
  LIST_TV_VIDEO_BY_ID: "/listTvVideoByID",
  GET_USER_VOLUME_DETAILS: "/getUserVolumeDetails",
  GET_NOTIFICATION_DETAILS: "/listTvVideoNotification",
  SEND_INVITATION: "/setUserInvite",
  GET_INVITED_USERS_URL: "/getInvitedUsers",
  DELETE_INVITED_USERS_URL: "/deleteInvitedUsers",
  GET_SAVED_VIDEO_URL: "/listTvSaveVideo",
  FETCH_INVITATION_DETAIL: "/getInvitedUsersShareDetails",
  SAVE_VIDEO_VIEW: "/saveVideoView",
  CHECK_INVITE_EMAIL: "/checkInviteEmail",
  LIST_APPLICATION_MENU: "/listAllApplicationMenu",
  LIST_VOLUME_WISE_SERIES: "/getVolumeSeries",
  GET_CATEGORY_SPECIFIC_DETAILS: "/getCategoryDetails",
  GET_USER_MEMBERSHIPS_VOLUMES: "/categoryWiseVolumes",
  GET_USER_APPLICATION_LIST: "/getUserApplicationList",
};

export default urls;
