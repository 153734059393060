// Smit 24-11
import { useEffect, useState } from "react";

const useDebounce = (value, debounceTime = 500) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setState(value);
    }, [debounceTime]);
    return () => clearTimeout(timeoutId);
  }, [value]);

  return state;
};

export default useDebounce;
