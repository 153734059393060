export const SET_USER = "SET_USER";

export const FETCH_TV_CATEGORIES = "FETCH_TV_CATEGORIES";
export const FETCH_TV_CATEGORIES_SUCCESS = "FETCH_TV_CATEGORIES_SUCCESS";
export const FETCH_TV_CATEGORIES_ERROR = "FETCH_TV_CATEGORIES_ERROR";

export const FETCH_TV_CATEGORIES_VIDEOS = "FETCH_TV_CATEGORIES_VIDEOS";
export const FETCH_TV_CATEGORIES_VIDEOS_SUCCESS =
  "FETCH_TV_CATEGORIES_VIDEOS_SUCCESS";
export const FETCH_TV_CATEGORIES_VIDEOS_ERROR =
  "FETCH_TV_CATEGORIES_VIDEOS_ERROR";

export const FETCH_TV_CATEGORIES_DETAILS = "FETCH_TV_CATEGORIES_DETAILS";
export const FETCH_TV_CATEGORIES_DETAILS_SUCCESS =
  "FETCH_TV_CATEGORIES_DETAILS_SUCCESS";
export const FETCH_TV_CATEGORIES_DETAILS_ERROR =
  "FETCH_TV_CATEGORIES_DETAILS_ERROR";

export const SET_TV_CATEGORY_PAGE_NO = "SET_TV_CATEGORY_PAGE_NO";
export const SET_TV_CATEGORY_VIDEOS_PAGE_NO = "SET_TV_CATEGORY_VIDEOS_PAGE_NO";
export const SAVED_TV_CATEGORY_VIDEOS_PAGE_NO =
  "SAVED_TV_CATEGORY_VIDEOS_PAGE_NO";

export const CLEAR_TV_PAGE_NO = "CLEAR_TV_PAGE_NO";
export const FETCH_USER_SUBSCRIPTION_SUCCESS =
  "FETCH_USER_SUBSCRIPTION_SUCCESS";

//For video player
export const FETCH_TV_VIDEO_PLAYER_BY_ID = "FETCH_TV_VIDEO_PLAYER_BY_ID";
export const FETCH_TV_VIDEO_PLAYER_BY_ID_SUCCESS =
  "FETCH_TV_VIDEO_PLAYER_BY_ID_SUCCESS";
export const FETCH_TV_VIDEO_PLAYER_BY_ID_ERROR =
  "FETCH_TV_VIDEO_PLAYER_BY_ID_ERROR";

export const SET_LOADING = "SET_LOADING";
export const SET_INITIAL_LOADING = "SET_INITIAL_LOADING";
export const SET_CATEGORY_ID = "SET_CATEGORY_ID";
export const SET_POST_VIDEO_DETAILS = "SET_POST_VIDEO_DETAILS";
//search
export const SET_GROW_TV_CATEGORY_SEARCH = "SET_GROW_TV_CATEGORY_SEARCH";
export const SET_USER_NOTIFICATION_DATA = "SET_USER_NOTIFICATION_DATA";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_VIDEO_VIEW_COUNT = "SET_VIDEO_VIEW_COUNT";
export const RES_MESSAGE = "RES_MESSAGE";
export const SET_INVITATION_DETAIL = "SET_INVITATION_DETAIL";

//get invited users
export const GET_INVITED_USERS = "GET_INVITED_USERS";
export const GET_INVITED_USERS_SUCCESS = "GET_INVITED_USERS_SUCCESS";
export const GET_INVITED_USERS_ERROR = "GET_INVITED_USERS_ERROR";

//delete invited users
export const DELETE_INVITED_USERS = "DELETE_INVITED_USERS";
export const DELETE_INVITED_USERS_SUCCESS = "DELETE_INVITED_USERS_SUCCESS";
export const DELETE_INVITED_USERS_ERROR = "DELETE_INVITED_USERS_ERROR";

// fetch saved videos
export const FETCH_SAVED_VIDEOS = "FETCH_SAVED_VIDEOS";
export const FETCH_SAVED_VIDEOS_SUCCESS = "FETCH_SAVED_VIDEOS_SUCCESS";
export const FETCH_SAVED_VIDEOS_ERROR = "FETCH_SAVED_VIDEOS_ERROR";

export const FETCH_VOLUME_WISE_SERIES_DATA = "FETCH_VOLUME_WISE_SERIES_DATA";
export const GET_USER_MEMBERSHIPS_VOLUMES = "GET_USER_MEMBERSHIPS_VOLUMES";
export const GET_USER_APPLICATION_LIST = "GET_USER_APPLICATION_LIST";

export const SET_CATEGORY_VOLUME_FILTER = "SET_CATEGORY_VOLUME_FILTER";
export const SET_CATEGORY_SERIES_FILTER = "SET_CATEGORY_SERIES_FILTER";
export const SET_CATEGORY_APPLICATION_FILTER =
  "SET_CATEGORY_APPLICATION_FILTER";
export const CLEAR_CATEGORY_FILTERS = "CLEAR_CATEGORY_FILTERS";
