import clearSearch from "assets/images/clear.png";
import TextInput from "components/Form/TextInput";
import InvitationModal from "components/Modal/InvitationModal";
import { ministries } from "constants/sites";
import useDebounce from "helpers/useDebounce";
import useOutsideClick from "helpers/useOutsideClick";
import { useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clearPageNo,
  getCategorySpecificDetail,
  getUserVolumeMembership,
  setApplicationFilter,
  setCategoryId,
  setGrowTVCategorySearch,
  setSeriesFilter,
  setVolumeFilter,
} from "store/actions";
import backImg from "../assets/images/back-arrow.png";
import SearchImage from "../assets/images/search-normal.svg";
import styles from "./Header.module.css";

const RowSection = () => {
  const urlValue = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = useSelector(state => state.Dashboard);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [seriesFilterData, setSeriesFilterData] = useState([]);
  const debounceSearch = useDebounce(search);
  const {
    categoryVideosDetail,
    categoriesVideos,
    membershipVolumes,
    volumeFilter,
    is_subscribe_user,
    catDetail,
  } = useSelector(state => state.Dashboard);

  useEffect(() => {
    !!urlValue.id && dispatch(getCategorySpecificDetail(urlValue.id));
  }, [dispatch, urlValue]);

  const ministryType = useMemo(
    () =>
      catDetail?.tv_category && catDetail?.categories_details[1].detail_value,
    [catDetail]
  );

  const ref = useRef();
  useOutsideClick(ref, () => {
    !search && setShowSearchBox(false);
  });

  useEffect(() => {
    if (
      ministries[ministryType] === 1 ||
      ministries[ministryType] === 2 ||
      ministries[ministryType] === 4
    ) {
      dispatch(
        // getUserVolumeMembership(ministries[ministryType])
        getUserVolumeMembership(catDetail?.tv_category_id)
      );
    }
  }, [dispatch, catDetail, ministryType]);

  // useEffect(() => {
  //   location?.state?.tv_category === "Tutorials" &&
  //     dispatch(getUserTutorialApplicationList());
  // }, [location, dispatch]);

  useEffect(() => {
    if (!!volumeFilter.volume) {
      membershipVolumes?.filter(ser =>
        ser.category_id == volumeFilter.volume
          ? setSeriesFilterData(ser.series.map(x => x))
          : null
      );
    } else {
      setSeriesFilterData([]);
    }
  }, [volumeFilter, ministryType, dispatch, location, membershipVolumes]);

  const handleGoBack = () => {
    dispatch(clearPageNo());
    navigate(-1);
  };

  const handleGoHome = () => {
    dispatch(clearPageNo());
    dispatch(setCategoryId(1));
    dispatch(setGrowTVCategorySearch(""));
    navigate("/");
  };

  const handleCloseInvite = () => {
    setIsOpen(!isOpen);
  };

  const handleClickSearch = () => {
    setShowSearchBox(!showSearchBox);
  };

  const handleClickInvite = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen && (
        <InvitationModal isOpen={isOpen} isClose={handleCloseInvite} />
      )}
      <div className="mt-4 d-flex flex-wrap justify-content-between">
        {!!urlValue.id ? (
          <div className={styles.header_top_section}>
            {catDetail?.tv_category !== undefined ? (
              <>
                <button
                  onClick={() => handleGoBack()}
                  className={styles.top_back_image}
                >
                  <img src={backImg} alt="back arrow" />
                </button>
                <h1 className="ms-3">
                  {catDetail?.tv_category ||
                    categoryVideosDetail?.video_title ||
                    categoriesVideos[0]?.category_name}
                </h1>
              </>
            ) : (
              <>
                <h1 className="ms-3">
                  {categoriesVideos[0]?.sycu_category?.category_title}
                </h1>
              </>
            )}
          </div>
        ) : (
          <div className={styles.header_top_section}>
            {location.pathname === "/" && debounceSearch ? (
              <>
                <button
                  onClick={() => handleGoHome()}
                  className={styles.top_back_image}
                >
                  <img src={backImg} alt="back arrow" />
                </button>
                <h1 className="ms-3">Search List</h1>{" "}
              </>
            ) : null}
          </div>
        )}
        <div className="d-flex dd-flex flex-wrap align-items-center">
          <div className="d-flex">
            {(urlValue.id && ministries[ministryType] === 1) ||
            (urlValue.id && ministries[ministryType] === 2) ? (
              <>
                <Form.Select
                  aria-label="Filter by Volume"
                  style={{ minWidth: "180px" }}
                  onChange={e =>
                    dispatch(
                      setVolumeFilter({ volume: e.target.value, series: "" })
                    )
                  }
                >
                  <option value="">Filter by Volume</option>
                  {membershipVolumes?.map(vol => (
                    <option value={vol.category_id}>
                      {vol.category_title}
                    </option>
                  ))}
                </Form.Select>
                <Form.Select
                  aria-label="Filter by Series"
                  style={{ minWidth: "150px" }}
                  className="ms-3"
                  value={volumeFilter.series}
                  onChange={e =>
                    dispatch(
                      setVolumeFilter({
                        volume: volumeFilter.volume,
                        series: e.target.value,
                      })
                    )
                  }
                >
                  <option value="">Filter by Series</option>
                  {seriesFilterData?.map(x => (
                    <option value={x.category_id}>{x.category_title}</option>
                  ))}
                </Form.Select>
              </>
            ) : urlValue.id && ministryType === "Tutorials" ? (
              <Form.Select
                aria-label="Filter by Product"
                style={{ minWidth: "150px" }}
                className="ms-3"
                onChange={e => dispatch(setApplicationFilter(e.target.value))}
              >
                <option value="">Filter by Product</option>
                {membershipVolumes?.map(tut => (
                  <option value={tut.category_id}>{tut.category_title}</option>
                ))}
              </Form.Select>
            ) : null}
          </div>
          {is_subscribe_user === 0 ? null : (
            <div className={styles.title_btns}>
              {/* {showSearchBox ? ( */}
              <div className={styles.search_bar} ref={ref}>
                <TextInput
                  value={search}
                  placeholder="Search here"
                  onChange={e =>
                    dispatch(setGrowTVCategorySearch(e.target.value))
                  }
                  style={{ paddingRight: "35px" }}
                />
                <div className={styles.round_dropdown}>
                  <span className={styles.gray_btn} onClick={handleClickSearch}>
                    {debounceSearch.length ? (
                      <img
                        src={clearSearch}
                        alt="clear search"
                        onClick={() => dispatch(setGrowTVCategorySearch(""))}
                      />
                    ) : (
                      <img src={SearchImage} alt="search here" />
                    )}
                  </span>
                </div>
              </div>
              <button
                onClick={() => handleClickInvite()}
                className="green_btn mr-2"
              >
                Invite User
              </button>
              {/* ) : null} */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RowSection;
