export const sitesData = [
  { id: 1, site: "Account", is_selected: false },
  { id: 2, site: "Grow Curriculum", is_selected: false },
  { id: 3, site: "Grow Builder", is_selected: false },
  { id: 4, site: "Grow Game", is_selected: false },
  { id: 5, site: "Hubs", is_selected: false },
  { id: 6, site: "Slidr", is_selected: false },
  { id: 7, site: "Grow People", is_selected: false },
  { id: 8, site: "Affiliate", is_selected: false },
  { id: 9, site: "Admin", is_selected: false },
  { id: 10, site: "Habit Student", is_selected: false },
  { id: 11, site: "Habit Kids", is_selected: false },
  { id: 12, site: "Grow Creative Board", is_selected: false },
];

export const ministries = {
  Kids: 1,
  Students: 2,
  NaN: 3,
  Tutorials: 4,
};
