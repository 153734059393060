import urls from "helpers/url_helper";
import { axiosAccount, axiosAdmin } from "services/api";
import {
  CLEAR_TV_PAGE_NO,
  FETCH_TV_CATEGORIES_ERROR,
  FETCH_TV_CATEGORIES_SUCCESS,
  FETCH_TV_CATEGORIES_VIDEOS_ERROR,
  FETCH_TV_CATEGORIES_VIDEOS_SUCCESS,
  SET_TV_CATEGORY_PAGE_NO,
  SET_TV_CATEGORY_VIDEOS_PAGE_NO,
  SET_POST_VIDEO_DETAILS,
  FETCH_TV_VIDEO_PLAYER_BY_ID_SUCCESS,
  FETCH_TV_VIDEO_PLAYER_BY_ID_ERROR,
  SET_LOADING,
  SET_GROW_TV_CATEGORY_SEARCH,
  SET_USER_NOTIFICATION_DATA,
  SET_INVITATION_DETAIL,
  GET_INVITED_USERS,
  GET_INVITED_USERS_SUCCESS,
  GET_INVITED_USERS_ERROR,
  DELETE_INVITED_USERS,
  DELETE_INVITED_USERS_SUCCESS,
  DELETE_INVITED_USERS_ERROR,
  FETCH_SAVED_VIDEOS_ERROR,
  FETCH_SAVED_VIDEOS_SUCCESS,
  SAVED_TV_CATEGORY_VIDEOS_PAGE_NO,
  RES_MESSAGE,
  SET_VIDEO_VIEW_COUNT,
  SET_INITIAL_LOADING,
  SET_CATEGORY_ID,
  FETCH_USER_SUBSCRIPTION_SUCCESS,
  FETCH_VOLUME_WISE_SERIES_DATA,
  GET_USER_MEMBERSHIPS_VOLUMES,
  GET_USER_APPLICATION_LIST,
  SET_CATEGORY_APPLICATION_FILTER,
  SET_CATEGORY_VOLUME_FILTER,
  SET_CATEGORY_SERIES_FILTER,
  CLEAR_CATEGORY_FILTERS,
  FETCH_TV_CATEGORIES_DETAILS,
  FETCH_TV_CATEGORIES_DETAILS_SUCCESS,
  FETCH_TV_CATEGORIES_DETAILS_ERROR,
} from "./actionTypes";

export const fetchCategory = (payload, cb) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const res = await axiosAccount.post(urls.LIST_TV_CATEGORY, payload);
      if (res.data?.data) {
        dispatch(getTVCategorySuccess(res?.data?.data));
        dispatch(getSubscribeUserSuccess(res?.data?.data));
        cb(res?.data?.data);
        dispatch(setLoading(false));
      }
    } catch (err) {
      dispatch(getTVCategoryError(err.message));
      dispatch(setLoading(false));
    }
  };
};

export const fetchCategoryVideos = payload => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      if (payload.page_no === 1) {
        dispatch(setInitialLoading(true));
      }
      const res = await axiosAccount.post(
        urls.LIST_TV_CATEGORY_VIDEOS,
        payload
      );
      if (res.data?.data) {
        dispatch(getTVCategoryVideosSuccess(res.data.data));
        dispatch(setLoading(false));
        if (payload.page_no === 1) {
          dispatch(setInitialLoading(false));
        }
      }
    } catch (err) {
      dispatch(getTVCategoryVideosError(err.message));
      dispatch(setLoading(false));
      if (payload.page_no === 1) {
        dispatch(setInitialLoading(false));
      }
    }
  };
};

export const fetchSavedVideos = payload => {
  return async dispatch => {
    try {
      // dispatch(setLoading(true));
      const res = await axiosAccount.post(urls.GET_SAVED_VIDEO_URL, payload);
      if (res.data?.data) {
        dispatch(getSavedVideosSuccess(res.data.data));
        // dispatch(setLoading(false));
      }
    } catch (err) {
      dispatch(getSavedVideosError(err.message));
      // dispatch(setLoading(false));
    }
  };
};

export const getTvVideoById = payload => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const res = await axiosAccount.get(
        `${urls.LIST_TV_VIDEO_BY_ID}/${payload}`
      );
      if (res.data?.data?.data) {
        dispatch(setLoading(false));
        dispatch(fetchTvVideoByIdSuccess(res?.data?.data?.data));
      }
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(fetchTvVideoByIdError(err.message));
    }
  };
};

//GET USER VOLUME
export const getNotificationData = payload => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const res = await axiosAccount.post(
        urls.GET_NOTIFICATION_DETAILS,
        payload
      );
      if (res.data?.data) {
        dispatch(setUserNotificationData(res.data.data));
        dispatch(setLoading(false));
      }
    } catch (err) {
      dispatch(getTVCategoryVideosError(err.message));
      dispatch(setLoading(false));
    }
  };
};

//get Invited Users
export const getInvitedUsers = () => async dispatch => {
  try {
    dispatch(getInvitedUsersLoading(true));

    const res = await axiosAccount.get(urls.GET_INVITED_USERS_URL);
    if (res?.status) {
      dispatch(getInvitedUsersSuccess(res.data.data.data));
      // cb();
    }
  } catch (err) {
    dispatch(getInvitedUsersError(false));
  }
};

export const getCategorySpecificDetail = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const res = await axiosAccount.get(`${urls.GET_CATEGORY_SPECIFIC_DETAILS}/${payload}`);
    if (res?.status) {
      dispatch(fetchCategoryWiseData(res.data.data[0]));
      dispatch(setLoading(false));
    }
  } catch (err) {
    dispatch(setLoading(false));
  }
};

export const getUserTutorialApplicationList = () => async dispatch => {
  try {
    dispatch(setLoading(true));
    const res = await axiosAccount.get(urls.GET_USER_APPLICATION_LIST);
    if (res?.status) {
      dispatch(fetchTutorialApplicationList(res.data.data.userApplicationList));
      dispatch(setLoading(false));
    }
  } catch (err) {
    dispatch(setLoading(false));
  }
};

export const getUserVolumeMembership = payload => async dispatch => {
  try {
    dispatch(setLoading(true));
    const res = await axiosAccount.get(
      `${urls.GET_USER_MEMBERSHIPS_VOLUMES}/${payload}`
    );
    if (res?.status) {
      dispatch(fetchMembershipVolumes(res.data.data.data));
      dispatch(setLoading(false));
    }
  } catch (err) {
    dispatch(setLoading(false));
  }
};

export const getVolumeWiseDataSeries = payload => async dispatch => {
  try {
    dispatch(setLoading(true));
    const res = await axiosAdmin.post(urls.LIST_VOLUME_WISE_SERIES, payload);
    if (res?.status) {
      dispatch(fetchVolumeSeries(res.data.data.series));
      dispatch(setLoading(false));
    }
  } catch (err) {
    dispatch(setLoading(false));
  }
};

export const updateCountAndIncrement = payload => async dispatch => {
  try {
    const res = await axiosAccount.post(urls.SAVE_VIDEO_VIEW, payload);
    if (res?.status) {
      dispatch(setVideoViewCountAndUpdate(payload));
    }
  } catch (err) {}
};

export const deleteInvitedUsers = (
  payload,
  handleClickCloseDeleteInvitation
) => {
  return async dispatch => {
    try {
      dispatch(deleteInvitedUsersLoading(true));
      const res = await axiosAccount.delete(
        `${urls.DELETE_INVITED_USERS_URL}/${payload}`
      );
      if (res.data) {
        dispatch(deleteInvitedUsersLoading(false));
        dispatch(deleteInvitedUsersSuccess(res.data));
        dispatch(getInvitedUsers());
        handleClickCloseDeleteInvitation();
      }
    } catch (err) {
      dispatch(deleteInvitedUsersError(false));
      dispatch(deleteInvitedUsersLoading(false));
    }
  };
};

//delete invited users
export const deleteInvitedUsersLoading = data => ({
  type: DELETE_INVITED_USERS,
  payload: data,
});

export const deleteInvitedUsersSuccess = data => ({
  type: DELETE_INVITED_USERS_SUCCESS,
  payload: data,
});
export const deleteInvitedUsersError = data => ({
  type: DELETE_INVITED_USERS_ERROR,
  payload: data,
});

//get invited users
export const getInvitedUsersLoading = data => ({
  type: GET_INVITED_USERS,
  payload: data,
});

export const getInvitedUsersSuccess = data => ({
  type: GET_INVITED_USERS_SUCCESS,
  payload: data,
});
export const getInvitedUsersError = data => ({
  type: GET_INVITED_USERS_ERROR,
  payload: data,
});

export const fetchCategoryWiseData = data => ({
  type: FETCH_TV_CATEGORIES_DETAILS,
  payload: data,
});

export const setVideoViewCountAndUpdate = data => ({
  type: SET_VIDEO_VIEW_COUNT,
  payload: data,
});

export const setInvitationDetails = data => ({
  type: SET_INVITATION_DETAIL,
  payload: data,
});

export const setPostVideoDetails = data => ({
  type: SET_POST_VIDEO_DETAILS,
  payload: data,
});

export const setUserNotificationData = data => ({
  type: SET_USER_NOTIFICATION_DATA,
  payload: data,
});

export const getTVCategorySuccess = data => ({
  type: FETCH_TV_CATEGORIES_SUCCESS,
  payload: data,
});

export const getSubscribeUserSuccess = data => ({
  type: FETCH_USER_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const fetchTutorialApplicationList = data => ({
  type: GET_USER_APPLICATION_LIST,
  payload: data,
});

export const fetchVolumeSeries = data => ({
  type: FETCH_VOLUME_WISE_SERIES_DATA,
  payload: data,
});

export const fetchMembershipVolumes = data => ({
  type: GET_USER_MEMBERSHIPS_VOLUMES,
  payload: data,
});

export const getTVCategoryError = data => ({
  type: FETCH_TV_CATEGORIES_ERROR,
  payload: data,
});

export const getTVCategoryVideosSuccess = data => ({
  type: FETCH_TV_CATEGORIES_VIDEOS_SUCCESS,
  payload: data,
});

export const getTVCategoryVideosError = data => ({
  type: FETCH_TV_CATEGORIES_VIDEOS_ERROR,
  payload: data,
});

export const getSavedVideosSuccess = data => ({
  type: FETCH_SAVED_VIDEOS_SUCCESS,
  payload: data,
});

export const getSavedVideosError = data => ({
  type: FETCH_SAVED_VIDEOS_ERROR,
  payload: data,
});

export const setTVCategoryPageNo = data => ({
  type: SET_TV_CATEGORY_PAGE_NO,
  payload: data,
});

export const setTVCategoryVideosPageNo = data => ({
  type: SET_TV_CATEGORY_VIDEOS_PAGE_NO,
  payload: data,
});

export const setSavedVideosPageNo = data => ({
  type: SAVED_TV_CATEGORY_VIDEOS_PAGE_NO,
  payload: data,
});

export const setApplicationFilter = data => ({
  type: SET_CATEGORY_APPLICATION_FILTER,
  payload: data,
});
export const setVolumeFilter = data => ({
  type: SET_CATEGORY_VOLUME_FILTER,
  payload: data,
});
export const setSeriesFilter = data => ({
  type: SET_CATEGORY_SERIES_FILTER,
  payload: data,
});

export const clearCategoryFilters = () => ({
  type: CLEAR_CATEGORY_FILTERS,
});

export const clearPageNo = () => ({
  type: CLEAR_TV_PAGE_NO,
});

export const setResponseMessage = data => ({
  type: RES_MESSAGE,
  payload: data,
});

export const fetchTvVideoByIdSuccess = data => ({
  type: FETCH_TV_VIDEO_PLAYER_BY_ID_SUCCESS,
  payload: data,
});

export const fetchTvVideoByIdError = data => ({
  type: FETCH_TV_VIDEO_PLAYER_BY_ID_ERROR,
  payload: data,
});

export const setLoading = data => ({
  type: SET_LOADING,
  payload: data,
});

export const setInitialLoading = data => ({
  type: SET_INITIAL_LOADING,
  payload: data,
});

export const setGrowTVCategorySearch = data => ({
  type: SET_GROW_TV_CATEGORY_SEARCH,
  payload: data,
});

export const setCategoryId = data => ({
  type: SET_CATEGORY_ID,
  payload: data,
});
