import {
  SET_AUTH,
  SET_AUTH_LOADING,
  SET_USER_PROFILE,
  SET_USER_VOLUME_DATA,
  LOGOUT,
} from "./actionTypes";

const initialState = {
  isAuth: false,
  user: null,
  volume_data: [],
  loading: true,
};

const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_AUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        user: payload,
      };
    case SET_USER_VOLUME_DATA:
      return {
        ...state,
        volume_data: payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        isAuth: false,
      };
    default:
      return state;
  }
};

export default loginReducer;
