import produce from "immer";
import {
  FETCH_TV_CATEGORIES,
  FETCH_TV_CATEGORIES_ERROR,
  FETCH_TV_CATEGORIES_SUCCESS,
  FETCH_TV_CATEGORIES_VIDEOS,
  FETCH_TV_CATEGORIES_VIDEOS_ERROR,
  FETCH_TV_CATEGORIES_VIDEOS_SUCCESS,
  SET_TV_CATEGORY_PAGE_NO,
  SET_TV_CATEGORY_VIDEOS_PAGE_NO,
  CLEAR_TV_PAGE_NO,
  FETCH_USER_SUBSCRIPTION_SUCCESS,
  FETCH_TV_VIDEO_PLAYER_BY_ID,
  FETCH_TV_VIDEO_PLAYER_BY_ID_SUCCESS,
  FETCH_TV_VIDEO_PLAYER_BY_ID_ERROR,
  SET_LOADING,
  SET_INITIAL_LOADING,
  SET_GROW_TV_CATEGORY_SEARCH,
  SET_USER_NOTIFICATION_DATA,
  SET_NOTIFICATION,
  SET_INVITATION_DETAIL,
  GET_INVITED_USERS,
  GET_INVITED_USERS_SUCCESS,
  GET_INVITED_USERS_ERROR,
  FETCH_SAVED_VIDEOS,
  FETCH_SAVED_VIDEOS_SUCCESS,
  FETCH_SAVED_VIDEOS_ERROR,
  SAVED_TV_CATEGORY_VIDEOS_PAGE_NO,
  RES_MESSAGE,
  DELETE_INVITED_USERS,
  SET_VIDEO_VIEW_COUNT,
  SET_CATEGORY_ID,
  SET_POST_VIDEO_DETAILS,
  FETCH_VOLUME_WISE_SERIES_DATA,
  GET_USER_MEMBERSHIPS_VOLUMES,
  GET_USER_APPLICATION_LIST,
  SET_CATEGORY_APPLICATION_FILTER,
  SET_CATEGORY_VOLUME_FILTER,
  SET_CATEGORY_SERIES_FILTER,
  CLEAR_CATEGORY_FILTERS,
  FETCH_TV_CATEGORIES_DETAILS,
  FETCH_TV_CATEGORIES_DETAILS_SUCCESS,
  FETCH_TV_CATEGORIES_DETAILS_ERROR,
} from "./actionTypes";

const initialState = {
  categories: [],
  is_subscribe_user: null,
  is_invited: null,
  pageNo: 1,
  pageRecord: 10,
  videoPageNo: 1,
  savedVideosPageNo: 1,
  count: 0, //change to 0 in deploy
  categoriesVideos: [],
  videosCount: 0,
  loading: false,
  initialLoading: false,
  categoryVideosDetail: {},
  search: "",
  notification_count: 0,
  notification_data: [],
  invitationDetail: [],
  invitedUserloading: false,
  invitedUsers: null,
  savedVideo: [],
  resMessage: "",
  deleteLoading: false,
  updatedVideoCount: 0,
  categoryId: null,
  videoDetails: {},
  volumeWiseSeries: [],
  membershipVolumes: [],
  tutorialApplicationList: [],
  volumeFilter: "",
  seriesFilter: "",
  applicationFilter: "",
  catDetail: {},
};

const dashboardReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SAVED_VIDEOS:
      return {
        ...state,
        // loading: true,
      };
    case FETCH_TV_CATEGORIES_DETAILS:
      return {
        ...state,
        catDetail: payload,
      };
    case GET_USER_APPLICATION_LIST:
      return {
        ...state,
        tutorialApplicationList: payload,
      };
    case FETCH_VOLUME_WISE_SERIES_DATA:
      return {
        ...state,
        volumeWiseSeries: payload,
      };
    case GET_USER_MEMBERSHIPS_VOLUMES:
      return {
        ...state,
        membershipVolumes: payload,
      };
    case SET_CATEGORY_ID:
      return {
        ...state,
        categoryId: 0,
      };
    case SET_POST_VIDEO_DETAILS:
      return {
        ...state,
        videoDetails: payload,
      };
    case SET_CATEGORY_APPLICATION_FILTER:
      return {
        ...state,
        applicationFilter: payload,
      };
    case SET_CATEGORY_SERIES_FILTER:
      return {
        ...state,
        seriesFilter: payload,
      };
    case SET_CATEGORY_VOLUME_FILTER:
      return {
        ...state,
        volumeFilter: payload,
      };
    case CLEAR_CATEGORY_FILTERS:
      return {
        ...state,
        volumeFilter: "",
        seriesFilter: "",
        applicationFilter: "",
      };
    case FETCH_SAVED_VIDEOS_SUCCESS:
      return {
        ...state,
        savedVideo:
          state.savedVideosPageNo === 1
            ? payload.savedVideos
            : [...state.savedVideo, ...payload.savedVideos],
        count: payload?.count,
        // loading: false,
      };
    case FETCH_SAVED_VIDEOS_ERROR:
      return {
        ...state,
        // loading: false,
      };
    case FETCH_TV_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case SET_INVITATION_DETAIL:
      return {
        ...state,
        invitationDetail: payload,
      };
    case SET_VIDEO_VIEW_COUNT:
      return {
        ...state,
        updatedVideoCount: payload,
      };
    case RES_MESSAGE:
      return {
        ...state,
        resMessage: payload,
      };
    case DELETE_INVITED_USERS:
      return {
        ...state,
        deleteLoading: payload,
      };
    case FETCH_TV_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories:
          state.pageNo === 1
            ? [...payload.catgory_data]
            : [...state.categories, ...payload.catgory_data],
        count: payload?.count,
        loading: false,
        categoriesVideos: [],
      };
    case FETCH_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        is_subscribe_user: payload.is_subscribe_user,
        is_invited: payload.is_invited,
        loading: false,
      };
    case FETCH_TV_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_TV_CATEGORIES_VIDEOS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TV_CATEGORIES_VIDEOS_SUCCESS:
      return {
        ...state,
        categoriesVideos:
          state.videoPageNo === 1
            ? [...payload.catgory_data]
            : [...state.categoriesVideos, ...payload.catgory_data],
        count: payload?.count,
        loading: false,
      };
    case FETCH_TV_CATEGORIES_VIDEOS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_TV_CATEGORY_PAGE_NO:
      return {
        ...state,
        pageNo: payload,
      };
    case SET_TV_CATEGORY_VIDEOS_PAGE_NO:
      return {
        ...state,
        videoPageNo: payload,
      };
    case SAVED_TV_CATEGORY_VIDEOS_PAGE_NO:
      return {
        ...state,
        savedVideosPageNo: payload,
      };
    case CLEAR_TV_PAGE_NO:
      return {
        ...state,
        videoPageNo: 1,
        pageNo: 1,
      };
    case FETCH_TV_VIDEO_PLAYER_BY_ID: //For video player
      return {
        ...state,
        // loading: true,
      };
    case FETCH_TV_VIDEO_PLAYER_BY_ID_SUCCESS:
      return {
        ...state,
        categoryVideosDetail: payload,
        // loading: false,
      };
    case FETCH_TV_VIDEO_PLAYER_BY_ID_ERROR:
      return {
        ...state,
        // loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_INITIAL_LOADING:
      return {
        ...state,
        initialLoading: payload,
      };
    case SET_GROW_TV_CATEGORY_SEARCH:
      return {
        ...state,
        search: payload,
      };
    case SET_USER_NOTIFICATION_DATA:
      return {
        ...state,
        notification_count: payload.readCount,
        notification_data: payload.data,
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification_data: payload,
        notification_count: 0,
      };
    //get invited user list
    case GET_INVITED_USERS:
      return {
        ...state,
        invitedUserloading: payload,
      };
    case GET_INVITED_USERS_SUCCESS:
      return {
        ...state,
        invitedUsers: payload,
        invitedUserloading: false,
      };
    case GET_INVITED_USERS_ERROR:
      return {
        ...state,
        invitedUserloading: false,
      };

    default:
      return state;
  }
});

export default dashboardReducer;
