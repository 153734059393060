const Loader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center full-screen">
      <div className="d-flex align-items-center flex-column">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
