import urls from "helpers/url_helper";
import { axiosAccount } from "services/api";
import Cookies, { clear, cookieKeys, get, remove } from "services/cookies";
import { clearLocal } from "services/localstorage";
import {
  SET_AUTH,
  SET_AUTH_LOADING,
  SET_USER_PROFILE,
  SET_USER_VOLUME_DATA,
  LOGOUT,
} from "./actionTypes";

export const fetchUserProfile = cb => async dispatch => {
  try {
    dispatch(setAuthLoading(true));

    const res = await axiosAccount.get(urls.GET_USER_PROFILE);
    if (res.status) {
      dispatch(setAuth(true));
      dispatch(setUserProfile(res.data.data.user));
      cb();
    }
  } catch (err) {
    dispatch(setAuthLoading(false));
  }
};

// Log out
export const logOutUser = () => {
  return async dispatch => {
    try {
      // dispatch(setAuthLoading(true))
      const res = await axiosAccount.post(urls.LOG_OUT_USER, {
        token: get("token"),
      });
      if (res.status === 200) {
        remove("token");
        clearLocal();
        clear();
        dispatch(logout());
        window.location.replace(
          `${process.env.REACT_APP_ACCOUNT_SITE_URL}/login?redirect=${window.location.href}&app_id=14`
        );
      }
      // dispatch(setAuthLoading(false))
    } catch (err) {
      // dispatch(setAuthLoading(false))
    }
  };
};

//GET USER VOLUME
export const fetchUserVolumeDetails = cb => async dispatch => {
  try {
    dispatch(setAuthLoading(true));

    const res = await axiosAccount.get(urls.GET_USER_VOLUME_DETAILS);
    if (res.status) {
      dispatch(setAuth(true));
      dispatch(setUserVolumeData(res.data.data.data));
      // cb();
    }
  } catch (err) {
    dispatch(setAuthLoading(false));
  }
};

export const setAuthLoading = data => ({
  type: SET_AUTH_LOADING,
  payload: data,
});

export const setAuth = data => ({
  type: SET_AUTH,
  payload: data,
});

export const setUserProfile = data => ({
  type: SET_USER_PROFILE,
  payload: data,
});

export const setUserVolumeData = data => ({
  type: SET_USER_VOLUME_DATA,
  payload: data,
});

export const logout = () => ({
  type: LOGOUT,
});
