import { useFormik } from "formik";
import urls from "helpers/url_helper";
import useOutsideClick from "helpers/useOutsideClick";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormFeedback, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { axiosAccount } from "services/api";
import {
  deleteInvitedUsers,
  fetchUserVolumeDetails,
  getInvitedUsers,
  setInvitationDetails,
  setResponseMessage,
  setUserVolumeData,
} from "store/actions";
import * as Yup from "yup";
import deleteImg from "../../assets/images/delete.png";
import inviteUserWarningImg from "../../assets/images/delete_icon.png";
import editImg from "../../assets/images/edit.png";
import inviteUserImg from "../../assets/images/inviteuser_icon.png";
import inviteUserSuccessImg from "../../assets/images/right.jpg";
import userDefault from "../../assets/images/userDefault.png";
import PageContent from "./PageContent";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const InvitationModal = ({ isOpen, isClose }) => {
  const [isCategoryPoup, setIsCategoryPopup] = useState(false);
  const [isCategoryEditPopup, setIsCategoryEditPopup] = useState({
    open: false,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  const [isSendInvitationSuccessPopup, setIsSendInvitationSuccessPopup] =
    useState(false);
  const dispatch = useDispatch();
  const [isSendInvitationPopup, setIsSendInvitationPopup] = useState(false);
  const [isDeleteInvitationPopup, setIsDeleteInvitationPopup] = useState(false);
  const { isAuth, volume_data } = useSelector(state => state.Login);
  const { invitationDetail, invitedUsers, invitedUserloading, deleteLoading } =
    useSelector(state => state.Dashboard);
  const [checkData, setCheckData] = useState();
  const [deleteId, setDeleteId] = useState(0);

  const ref = useRef();
  useOutsideClick(ref, () => {
    setIsSendInvitationPopup(false);
  });

  const isInvitationClose = () => {
    setIsCategoryPopup(!isCategoryPoup);
  };

  const isEditInvitationClose = () => {
    setIsCategoryEditPopup(!isCategoryEditPopup);
  };

  const isSendInvitation = () => {
    setIsSendInvitationSuccessPopup(true);
    setIsCategoryPopup(false);
  };
  const isInvitationWarningClose = () => {
    setIsSendInvitationSuccessPopup(!isSendInvitationSuccessPopup);
  };

  const isInvitationSuccessSend = () => {
    setIsCategoryPopup(!isCategoryPoup);
  };

  const isInvitationWarningOpen = async () => {
    const payload = {
      email: invitationDetail?.email,
      inviteData: checkData || volume_data,
      sycu_tv_share_video_series_id:
        invitationDetail.sycu_tv_share_video_series_id
          ? invitationDetail.sycu_tv_share_video_series_id
          : 0,
    };
    try {
      setLoading(true);
      const res = await axiosAccount.put(urls.SEND_INVITATION, payload);
      if (res.status) {
        setLoading(false);

        setIsSendInvitationSuccessPopup(false);
        invitationDetail.sycu_tv_share_video_series_id &&
          toast.success("You have successfully updated video permissions.");
        if (invitationDetail.sycu_tv_share_video_series_id) {
          setIsCategoryEditPopup({ open: false, data: [] });
        } else {
          // setIsSendInvitationPopup(true);
          dispatch(getInvitedUsers());
          setIsCategoryPopup(false);
          // isClose();
        }
      }
    } catch (err) {
      setLoading(false);
      setIsSendInvitationSuccessPopup(false);
      !invitationDetail.sycu_tv_share_video_series_id &&
        setIsSendInvitationPopup(false);
      toast.error(err.response?.data?.message || err.message);
      dispatch(setResponseMessage(err.message));
    }
  };

  const isInvitationWarningSuccessClose = () => {
    setIsSendInvitationPopup(!isSendInvitationPopup);
  };

  const handleClickDeleteInvitation = id => {
    setDeleteId(id);
    setIsDeleteInvitationPopup(true);
  };

  const handleClickCloseDeleteInvitation = () => {
    setIsDeleteInvitationPopup(!isDeleteInvitationPopup);
  };

  const handleClickConfirmDeleteInvitation = () => {
    dispatch(deleteInvitedUsers(deleteId, handleClickCloseDeleteInvitation));
  };

  useEffect(() => {
    resetForm();
  }, [isClose]);

  const onSubmit = async values => {
    setLoading(true);
    if (values.email) {
      try {
        const res = await axiosAccount.put(urls.CHECK_INVITE_EMAIL, {
          email: values.email,
        });
        if (res.data.statusCode === 200) {
          dispatch(setInvitationDetails(values));
          // isClose();
          setIsCategoryPopup(true);
          setLoading(false);
          onCloseUserEmailModal();
          // toast.success("Invitation sent successfully!");
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    const getVolumeData = () => {
      if (isAuth) {
        if (isOpen) {
          dispatch(getInvitedUsers());
          dispatch(fetchUserVolumeDetails());
        }
      }
    };
    getVolumeData();
  }, [isOpen]);

  const onCloseUserEmailModal = () => {
    // isClose();
    resetForm();
  };

  const handleFetchInvitationDetail = async row => {
    dispatch(setInvitationDetails(row));
    setIsCategoryEditPopup({
      open: true,
      data: row,
    });
    try {
      setLoadingPermissions(true);
      const res = await axiosAccount.get(
        `${urls.FETCH_INVITATION_DETAIL}/${row.sycu_tv_share_video_series_id}`
      );
      if (res) {
        setLoadingPermissions(false);
        dispatch(setUserVolumeData(res.data.data.data));
      }
    } catch (err) {
      setLoadingPermissions(false);
    }
  };

  const handleInviteSendData = () => {
    setIsCategoryPopup(false);
    isInvitationWarningOpen();
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    touched,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <Modal
        className="video-popup modal-dialog-centered"
        isOpen={isOpen}
        size="xs"
      >
        <ModalHeader toggle={isClose}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span className="modal-title mt-0">
                <h3 className="m-0">Invite</h3>
              </span>
            </div>
          </div>
        </ModalHeader>

        <ModalBody className="player-box">
          <form onSubmit={handleSubmit}>
            <div className="input_field">
              <div className="input-div">
                <Input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  placeholder="Enter Email Account"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={!!errors.email && touched.email}
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </div>
              <button
                type="submit"
                disabled={invitedUserloading || loading}
                className="btn btn-primary"
              >
                {loading ? "Loading..." : "Invite"}
              </button>
            </div>
          </form>
          <div className="listing">
            <ul>
              {invitedUserloading ? (
                <center>Loading...</center>
              ) : (
                !!invitedUsers &&
                invitedUsers.map((row, i) => {
                  return (
                    <li key={i}>
                      <p>
                        <img
                          src={
                            !!row?.profile_image
                              ? row?.profile_image
                              : userDefault
                          }
                          alt=""
                          className="ms-0 mx-2"
                        />
                        {row?.email ? row?.email : "No Data"}
                        {row?.sycu_tv_share_video_series_id === 0 && "(You)"}
                      </p>
                      <p>
                        {!!row?.sycu_tv_share_video_series_id ? (
                          <>
                            <button
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              data-bs-target="#inviteuser"
                              className="grey_btn p-0 m-0 bg-transparent"
                              onClick={() => {
                                handleFetchInvitationDetail(row);
                              }}
                              title="Edit"
                            >
                              <img src={editImg} alt="" />
                            </button>
                            <button
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              data-bs-target="#delete"
                              title="Delete"
                              className="grey_btn p-0 bg-transparent"
                              onClick={() =>
                                handleClickDeleteInvitation(
                                  row?.sycu_tv_share_video_series_id
                                )
                              }
                            >
                              <img src={deleteImg} alt="" />
                            </button>
                          </>
                        ) : (
                          "Owner"
                        )}
                      </p>
                    </li>
                  );
                })
              )}
            </ul>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        className="video-popup modal-dialog-centered"
        isOpen={isCategoryEditPopup.open}
        size="lg"
      >
        <ModalHeader toggle={""} className="category-modal-header">
          <div className="d-flex justify-content-between align-items-center">
            <span className="modal-title mt-0">
              <h3 className="m-0">Categories</h3>
            </span>
            <div className="d-inline-block">
              <button className="grey_btn" onClick={isEditInvitationClose}>
                Cancel
              </button>
              <button className="green_btn" onClick={isInvitationWarningOpen}>
                {loading ? "Changing..." : "Change Permissions"}
              </button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="player-box">
          <div className="accordion" id="accordionExample">
            {!loadingPermissions ? (
              <PageContent setCheckData={setCheckData} type={1} />
            ) : (
              <div>
                <p className="text-center">Loading...</p>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        className="video-popup modal-dialog-centered"
        isOpen={isCategoryPoup}
        size="lg"
      >
        <ModalHeader toggle={""} className="category-modal-header">
          <div className="d-flex justify-content-between align-items-center">
            <span className="modal-title mt-0">
              <h3 className="m-0">Categories</h3>
            </span>
            <div className="d-inline-block">
              <button className="grey_btn" onClick={isInvitationClose}>
                Cancel
              </button>
              <button className="green_btn" onClick={isInvitationWarningOpen}>
                {loading ? "Sending..." : "Send Invite"}
              </button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="player-box">
          <div className="accordion" id="accordionExample">
            {volume_data ? (
              <PageContent setCheckData={setCheckData} type={0} />
            ) : (
              <div>
                <p className="text-center">
                  Add Videos in Categories to Send invitation!
                </p>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
      {/***************** Warning Alert code *******************/}
      <Modal
        className="video-popup common invitation-warning-modal modal-dialog-centered"
        isOpen={isSendInvitationSuccessPopup}
        size="xs"
      >
        <ModalHeader
          toggle={isInvitationWarningClose}
          className="category-modal-header"
        ></ModalHeader>
        <ModalBody className="player-box text-center">
          <img src={inviteUserImg} alt="" />
          <div className="listing">
            <p>Are you sure you want to invite to this user?</p>
          </div>
          <div className="footer">
            <button
              onClick={isInvitationWarningClose}
              className="grey_btn border-transparent"
            >
              NO
            </button>

            <button
              onClick={isInvitationWarningOpen}
              className="green_btn border-transparent"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              data-bs-target="#success"
            >
              {loading ? "Sending..." : "Yes"}
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/*****************Warning Success popup code *******************************/}
      <div ref={ref}>
        <Modal
          className="video-popup common invitation-warning-modal modal-dialog-centered"
          isOpen={isSendInvitationPopup}
          size="xs"
          ref={ref}
        >
          <ModalHeader
            toggle={() => setIsSendInvitationPopup(false)}
            className="category-modal-header"
          ></ModalHeader>
          <ModalBody className="player-box text-center">
            <img
              className="invite-success-image"
              src={inviteUserSuccessImg}
              alt=""
            />
            <div className="listing">
              <p>Congratulations your Invitation has been Sent Successfully!</p>
            </div>
          </ModalBody>
        </Modal>
      </div>

      {/************************* Delete Invitation Popup ****************************/}
      {/***************** Warning Alert code *******************/}
      <Modal
        className="video-popup common invitation-warning-modal modal-dialog-centered"
        isOpen={isDeleteInvitationPopup}
        size="xs"
      >
        <ModalHeader
          // toggle={handleClickCloseDeleteInvitation}
          className="category-modal-header"
        ></ModalHeader>
        <ModalBody className="player-box text-center">
          <img src={inviteUserWarningImg} alt="" />
          <div className="listing">
            <p>Are you sure you want to delete this Invitation ?</p>
          </div>
          <div className="footer">
            <button
              onClick={handleClickCloseDeleteInvitation}
              className="grey_btn"
            >
              CANCEL
            </button>
            <button
              className="red_btn"
              data-bs-dismiss="modal"
              onClick={handleClickConfirmDeleteInvitation}
            >
              {deleteLoading ? "DELETING..." : "DELETE"}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InvitationModal;
