import Grow from "assets/images/grow.png";
import userImg from "assets/images/user-img-1.png";
import React from "react";
import { Offcanvas, OffcanvasBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logOutUser } from "store/actions";
import { getCloudFrontImgUrl } from "utiles/cloudFornt";
import styles from "./Sidebar.module.css";

const Sidebar = ({ isOpen = false, close }) => {
  const { user } = useSelector(state => state.Login);
  const { applications, applicationLoading } = useSelector(
    state => state.header
  );

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logOutUser());
  };
  const getRndInteger = (min, max) =>
    Math.floor(Math.random() * (max - min)) + min;
  const selected = url => {
    return window.location.origin + "/" === url;
  };
  const Loader = () => {
    return [...Array(6).keys()].map(index => (
      <li key={index} className="placeholder-glow p-2">
        <span className="site-logo placeholder col-2"></span>
        <p className={`placeholder col-${getRndInteger(4, 7)} mb-0`}></p>
      </li>
    ));
  };

  return (
    <Offcanvas className={styles.profile_canvas} show={isOpen} placement="end">
      <OffcanvasBody className="p-0">
        <ul className={styles.sidebarMenuInner}>
          <div className={styles.user}>
            <img
              src={
                !!user && !!user.profile_image
                  ? getCloudFrontImgUrl(user.profile_image)
                  : userImg
              }
              alt="user-img"
              className="user-img"
            />
            <label className={styles.close_sidebar} onClick={close}>
              {/* <img src={CloseIcon} alt="" /> */}
              <i className="fa fa-times"></i>
            </label>

            {!!user && !!user.display_name && (
              <h3 className="mtb10">{user.display_name}</h3>
            )}
            {!!user && !!user.email && (
              <p className={styles.header_user_email}>{user.email}</p>
            )}
            <div className="siderbar-btn">
              <Link
                to={{ pathname: process.env.REACT_APP_ACCOUNT_SITE_URL }}
                className={`0btn btn-size ${styles.account_btn}`}
                target="_parent"
              >
                Account
              </Link>

              <Link
                to=""
                className={`btn btn-size ${styles.logout_btn}`}
                onClick={handleLogout}
              >
                Logout
              </Link>
            </div>
          </div>
          <li>
            <h5 className={styles.heading_text}>Applications</h5>
            <ul className={styles.application_submenu}>
              {applicationLoading ? (
                <Loader />
              ) : (
                applications?.map((site, i) => (
                  <React.Fragment key={site.site_id}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer nav-link application-submenu"
                      href={site.url}
                      key={i}
                    >
                      <li
                        className={`${
                          !!selected(site.url) ? "active" : ""
                        }`}
                        key={i}
                      >
                        {site.small_logo && (
                          <img
                            src={
                              !!site.small_logo
                                ? getCloudFrontImgUrl(site.small_logo)
                                : Grow
                            }
                            alt=""
                          />
                        )}
                        {site.title}
                      </li>
                    </a>
                  </React.Fragment>
                ))
              )}
            </ul>
          </li>
        </ul>
        {[1, 2].includes(user?.user_role) && (
          <div className={styles.admin_btn}>
            <Link
              target="_parent"
              className={styles.btn_edit_profile}
              to={{ pathname: process.env.REACT_APP_ADMIN_SITE_URL }}
            >
              Go To Admin Panel
            </Link>
          </div>
        )}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default Sidebar;
