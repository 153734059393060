import urls from "helpers/url_helper";
import { toast } from "react-toastify";
import { axiosAccount } from "services/api";
import {
  FETCH_FOOTER_MENU,
  FETCH_FOOTER_MENU_LOADING,
  FETCH_HEADER_MENU_LOADING,
  FETCH_HEADER_MENU,
  FETCH_APPLICATION_LOADING,
  FETCH_APPLICATION,
} from "./actionTypes";

export const fetchHeader = payload => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_HEADER_MENU_LOADING, payload: true });
      const res = await axiosAccount.post(urls.LIST_APPLICATION_MENU, payload);

      if (res) {
        dispatch({
          type: FETCH_HEADER_MENU,
          payload: res.data.data.application_menu,
        });
      }
      dispatch({ type: FETCH_HEADER_MENU_LOADING, payload: false });
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({ type: FETCH_HEADER_MENU_LOADING, payload: false });
    }
  };
};

export const fetchFooter = payload => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_FOOTER_MENU_LOADING, payload: true });
      const res = await axiosAccount.post(urls.LIST_APPLICATION_MENU, payload);

      if (res) {
        dispatch({
          type: FETCH_FOOTER_MENU,
          payload: res.data.data.application_menu,
        });
      }
      dispatch({ type: FETCH_FOOTER_MENU_LOADING, payload: false });
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({ type: FETCH_FOOTER_MENU_LOADING, payload: false });
    }
  };
};

export const fetchApplication = payload => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_APPLICATION_LOADING, payload: true });
      const res = await axiosAccount.get(urls.LIST_SITE);

      if (res) {
        dispatch({
          type: FETCH_APPLICATION,
          payload: res.data.data.siteList,
        });
      }
      dispatch({ type: FETCH_APPLICATION_LOADING, payload: false });
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({ type: FETCH_APPLICATION_LOADING, payload: false });
    }
  };
};
