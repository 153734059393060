import mainLogo from "assets/images/main-logo.png";
import userImg from "assets/images/user-img-1.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchApplication, getTvVideoById } from "store/actions";
import { getCloudFrontImgUrl } from "utiles/cloudFornt";
import styles from "./Header.module.css";
import Sidebar from "./Sidebar";

const Header = () => {
  const { user } = useSelector(state => state.Login);
  const urlValue = useParams();
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (!!urlValue.category_id) {
      dispatch(getTvVideoById(urlValue?.id + "/" + urlValue?.category_id));
    }
  }, [dispatch, urlValue.category_id, urlValue?.id]);

  useEffect(() => {
    dispatch(fetchApplication());
  }, [dispatch]);

  return (
    <div>
      <div className={styles.title_flex}>
        <h1>
          <Link to="/" className="d-flex align-items-center">
            <img src={mainLogo} alt="back arrow" className={styles.user_logo} />
          </Link>
        </h1>

        <div className={styles.title_btns}>
          <div className="menu-container">
            <div
              className="d-flex cursor-pointer"
              id="openSidebarMenu"
              onClick={() => setIsSidebarOpen(true)}
            >
              <img
                src={
                  user?.profile_image
                    ? getCloudFrontImgUrl(user?.profile_image)
                    : userImg
                }
                alt="Header Avatar"
                className={styles.user_image}
              />

              <span className={styles.user_header_name}>
                {user?.first_name ? user?.first_name : "Loading..."}
                <i className="fas fa-chevron-down mx-2 mt-1"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Sidebar isOpen={isSidebarOpen} close={() => setIsSidebarOpen(false)} />
    </div>
  );
};

export default Header;
