import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RouteConstants } from "./RouteConstants";

import RequiredAuth from "components/common/RequiredAuth";
import Unauthorized from "components/common/Unauthorized";
import _404 from "components/common/_404";

// Use lazy loaded pages

const Dashboard = lazy(() => import("modules/dashboard/pages"));
const Login = lazy(() => import("modules/login/pages"));
const Details = lazy(() => import("modules/dashboard/pages/details"));
const PostDetails = lazy(() => import("modules/dashboard/pages/postDetails"));

const routesConfig = {
  common: [
    {
      path: "unauthorized",
      component: Unauthorized,
    },
    { path: "*", component: _404 },
  ],
  private: [
    {
      path: "/",
      component: RequiredAuth,
      children: [
        {
          index: true,
          component: Dashboard,
        },
        {
          path: ":id",
          component: () => <Details />,
        },
        {
          path: "category/:category_id/:id",
          component: () => <PostDetails />,
        },
      ],
    },
  ],
  public: [
    {
      path: RouteConstants?.login,
      component: Outlet,
      children: [{ index: true, component: () => <Login /> }],
    },
    {
      path: "*",
      component: <Navigate to={RouteConstants.dashboard} />,
    },
  ],
};

export default routesConfig;
