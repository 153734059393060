import axios from "axios";
import store from "store";
import { logout } from "store/actions";
import { clear, get, set } from "./cookies";

// TODO: check this before deploy
const API_URL = `${process.env.REACT_APP_BASE_API_URL}/api`;
const API_URL_ACCOUNT = `${process.env.REACT_APP_API_ACCOUNT_URL}/api/v1`;
// const API_URL_ACCOUNT = `http://192.168.1.143:8181/api/v1`;
const API_URL_ADMIN = `${process.env.REACT_APP_API_ADMIN_URL}/api/v1`;

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = req => {
    // Send Bearer token on every request
    const token = get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Bearer ")
        ? token
        : "Bearer " + token;
    return req;
  };

  _responseMiddleware = response => {
    //  Do something on every success full response
    if (response?.data?.data?.token) {
      set("token", response.data.data.token);
    }
    return response;
  };

  _responseErr = error => {
    if (error?.response?.data?.message == "Permission Reverted") {
      window.location.replace("/");
    }
    if (error?.response?.status === 401) {
      clear();
      store.dispatch(logout());
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };
}

const axiosAccount = new Axios(API_URL_ACCOUNT).axios;
const axiosPeople = new Axios(API_URL).axios;
const axiosAdmin = new Axios(API_URL_ADMIN).axios;

export { axiosPeople, axiosAccount, axiosAdmin };
